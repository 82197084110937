import React, { useState } from 'react'
import { Link, usePage } from '@inertiajs/inertia-react'
import { Layout, Menu, Space } from 'antd'
import Anticon from '../Anticon'
import FolderIcon from '../../../assets/icons/folder.svg'
import HouseIcon from '../../../assets/icons/house.svg'
import BookIcon from '../../../assets/icons/book.svg'
import './LeftMenu.scss'
import AppLogo from './AppLogo'
import clsx from 'clsx'
import useZiggy from '../../hooks/useZiggy'
import { ROLES } from '../../constants/roles'
import useAuthUser from '../../hooks/useAuthUser'

const MY_FOLDERS = 'my_folders'
const PRODUCTS = 'products'
const MEDIA_LIBRARY = 'media_library'

const REAL_ESTATE_FOLLOW_UP = 'real_estate_follow_up'
const SCPI_FOLLOW_UP = 'scpi_follow_up'
const SCPI_PRODUCTS = 'scpi_products'
const REAL_ESTATE_PRODUCT = 'real_estate_product'
const MY_RECORD = 'my_record'
const INVOICE_FOLLOW_UP = 'invoice_follow_up'

const LeftMenu = () => {
    const { items, user } = usePage().props
    const { getRoute } = useZiggy()
    const { hasRole } = useAuthUser()

    const [openKey, setOpenKey] = useState(null)

    const handleOpen = (item) => {
        if (item.item_name === openKey || !item?.sub_items) {
            handleClose()
            return
        }
        setOpenKey(item.item_name)
    }

    const handleClose = () => {
        setOpenKey(null)
    }

    const linkImmo = () => {
        let url
        if (hasRole([ROLES.Accountant])) {
            url = getRoute('real-estate.management-forms.index')
        } else if (hasRole([ROLES.PromoterSale, ROLES.AffiliateSale, ROLES.InternalForce])) {
            url = getRoute('real-estate.management-forms.promoter-sale.index')
        } else if (hasRole([ROLES.PromoterAdmin, ROLES.AffiliateAdmin])) {
            url = getRoute('real-estate.management-forms.promoter-admin.index')
        } else {
            url = getRoute('real-estate.intermediary-relay-forms.index')
        }

        return url
    }

    const linkScpi = () => {
        let url
        if (hasRole([ROLES.BackOfficeUser])) {
            url = getRoute('scpi.management-forms.index')
        } else {
            url = getRoute('scpi.subscription-forms.index')
        }

        return url
    }

    const menuItems = {
        [MY_FOLDERS]: (
            <Space direction="vertical">
                <Anticon icon={FolderIcon} />

                <span className="title">Mes dossiers</span>
            </Space>
        ),
        [PRODUCTS]: (
            <Space direction="vertical">
                <Anticon icon={HouseIcon} />

                <span className="title">Produits</span>
            </Space>
        ),
        [MEDIA_LIBRARY]: (
            <Link href={getRoute('library.theme.index')}>
                <Space direction="vertical">
                    <Anticon icon={BookIcon} />

                    <span className="title">Bibliothèque</span>
                </Space>
            </Link>
        ),
    }

    const myRecordHref = () => {
        if (hasRole([ROLES.CompanyAdmin]) && user?.companies) {
            return getRoute('management-companies.show', { managementCompany: user?.companies[0]?.id })
        }
        if (hasRole([ROLES.PromoterAdmin]) && user?.companies) {
            return getRoute('real-estate.promoters.show', { promoter: user?.companies[0]?.id })
        }
        return undefined
    }

    const submenuItems = {
        [REAL_ESTATE_FOLLOW_UP]: {
            title: 'Suivi immobilier',
            href: linkImmo(),
        },
        [SCPI_FOLLOW_UP]: {
            title: 'Suivi SCPI',
            href: linkScpi(),
        },
        [INVOICE_FOLLOW_UP]: {
            title: 'Suivi facturation',
            href: getRoute('real-estate.invoice.index'),
        },
        [SCPI_PRODUCTS]: { title: 'SCPI', href: getRoute('products.scpi.sale-index') },
        [REAL_ESTATE_PRODUCT]: {
            title: 'Immobilier',
            href: getRoute('real-estate.products.index'),
        },
        [MY_RECORD]: {
            title: 'Ma fiche',
            href: myRecordHref(),
        },
    }

    const LeftMenuSubmenu = (item) => {
        if (!item?.sub_items?.length) {
            return null
        }
        return (
            <div
                key={`${item.item_name}-LeftMenuSubmenu`}
                className={clsx('LeftMenu-submenu', { open: openKey === item.item_name })}
            >
                <div className="LeftMenu-submenu-title">{menuItems[item.item_name]}</div>

                <Menu
                    mode="inline"
                    items={item.sub_items.map((sub_item) => ({
                        label: (
                            <Link
                                href={submenuItems[sub_item.item_name]?.href ?? ''}
                                onClick={handleClose}
                                data-cy={sub_item.item_name}
                            >
                                {submenuItems[sub_item.item_name].title}
                            </Link>
                        ),
                    }))}
                />
            </div>
        )
    }

    const LeftMenuItem = (item) => {
        return [
            <button
                key={`${item.item_name}-LeftMenuItem`}
                className="LeftMenu-menu-item btn-no-ui"
                onClick={() => handleOpen(item)}
                data-cy={item.item_name}
            >
                {menuItems[item.item_name]}
            </button>,
            LeftMenuSubmenu(item),
        ]
    }

    return (
        <>
            <Layout.Sider className={clsx('LeftMenu', { 'submenu-open': Boolean(openKey) })} theme="light" width={170}>
                <Link href={getRoute('dashboard')} className="app-logo-link">
                    <AppLogo />
                </Link>

                <div className="LeftMenu-menu">{items.sidebar.map(LeftMenuItem)}</div>
            </Layout.Sider>

            <button
                className={clsx('btn-no-ui', 'LeftMenu-overlay', { active: Boolean(openKey) })}
                onClick={handleClose}
            />
        </>
    )
}

export default LeftMenu
